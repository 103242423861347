import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { createClient } from "@supabase/supabase-js";
import DataCard from "../components/DataCard";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const DISCORD_WEBHOOK_URL = process.env.REACT_APP_WEBHOOK;

const supabase = createClient(supabaseUrl, supabaseAnonKey);

const sendDiscordWebhook = async (email, webhookUrl, ownerId) => {
  const embed = {
    fields: [
      {
        name: "🤖 Nouveau visiteur:",
        value:
          "Email: " +
          "||" +
          email +
          "||\n[🔗 LINK](https://public.firststock.fr/?id=" +
          ownerId +
          ")",
      },
    ],
    color: 16711680,
  };

  const webhookPayload = {
    username: "FIRST STOCK",
    embeds: [embed],
  };

  try {
    await fetch(DISCORD_WEBHOOK_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(webhookPayload),
    });

    if (webhookUrl) {
      const embedWithoutEmail = {
        fields: [
          {
            name: "🤖 Nouveau visiteur:",
            value: "Une nouvelle personne vient de voir ton stock !",
          },
        ],
        color: 16711680,
      };

      const webhookPayloadWithoutEmail = {
        username: "FIRST STOCK",
        avatar_url: "https://public.firststock.fr/app.png",
        embeds: [embedWithoutEmail],
      };

      await fetch(webhookUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(webhookPayloadWithoutEmail),
      });
    }
  } catch (error) {
    console.error("Error sending webhook:", error);
  }
};

const Home = ({ setUserName }) => {
  const [fetchError, setFetchError] = useState(null);
  const [stockDatas, setStockDatas] = useState([]);
  const [vetementsDatas, setVetementsDatas] = useState([]);
  const [objetsDatas, setObjetsDatas] = useState([]);
  const [billeterieDatas, setBilleterieDatas] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [hidePopup, setHidePopup] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const showTimer = setTimeout(() => {
      setShowPopup(true);
    }, 1000);

    const hideTimer = setTimeout(() => {
      setHidePopup(true);
    }, 5000);

    const removePopupTimer = setTimeout(() => {
      setShowPopup(false);
    }, 5500);

    return () => {
      clearTimeout(showTimer);
      clearTimeout(hideTimer);
      clearTimeout(removePopupTimer);
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const ownerId = params.get("id");

    if (!ownerId) {
      setFetchError("ID parameter is missing in the URL");
      return;
    }

    const fetchDatas = async () => {
      let { data: ownerData, error: ownerError } = await supabase
        .from("user")
        .select("email, name, webhook")
        .eq("idpublic", ownerId)
        .single();

      if (ownerError || !ownerData) {
        setFetchError("Could not fetch data");
        return;
      }

      const ownerEmail = ownerData.email;
      const ownerWebhookUrl = ownerData.webhook;
      setUserName(ownerData.name);

      sendDiscordWebhook(ownerEmail, ownerWebhookUrl, ownerId);

      let { data, error } = await supabase
        .from("data")
        .select()
        .match({ owner: ownerEmail });

      if (error) {
        setFetchError("Could not fetch data");
      } else if (data) {
        const stock = data
          .filter(
            (item) => item.category === "SNEAKERS" && item.type === "STOCK"
          )
          .sort((a, b) =>
            a.title && b.title ? a.title.localeCompare(b.title) : 0
          );
        const vetements = data
          .filter(
            (item) => item.category === "VETEMENTS" && item.type === "STOCK"
          )
          .sort((a, b) =>
            a.title && b.title ? a.title.localeCompare(b.title) : 0
          );
        const objets = data
          .filter((item) => item.category === "OBJETS" && item.type === "STOCK")
          .sort((a, b) =>
            a.title && b.title ? a.title.localeCompare(b.title) : 0
          );
        const billeterieValide = data
          .filter(
            (item) => item.category === "BILLETERIE" && item.type === "STOCK"
          )
          .filter((item) => !isNaN(new Date(item.dateevent).getTime()))
          .sort((a, b) => new Date(a.dateevent) - new Date(b.dateevent));

        const billeterieInvalide = data
          .filter(
            (item) => item.category === "BILLETERIE" && item.type === "STOCK"
          )
          .filter((item) => isNaN(new Date(item.dateevent).getTime()));
        const billeterie = [...billeterieValide, ...billeterieInvalide];

        setStockDatas(stock);
        setVetementsDatas(vetements);
        setObjetsDatas(objets);
        setBilleterieDatas(billeterie);
        setFetchError(null);
      }
    };

    fetchDatas();
  }, [location.search, setUserName]);

  return (
    <div className="page home">
      {fetchError && <p>{fetchError}</p>}

      {/* Display popup */}
      {showPopup && (
        <div
          className={`popup ${hidePopup ? "hide" : ""}`}
          onClick={() =>
            (window.location.href = "https://whop.com/first-stock/")
          }
          style={{ cursor: "pointer" }}
        >
          Ce stock est géré sur l'app FirstStock 🔗
        </div>
      )}

      <div className="datas">
        {/* Display SNEAKERS items */}
        {stockDatas.length > 0 && (
          <div>
            <h2>Sneakers:</h2>
            <div className="data-grid">
              {stockDatas.map((data) => (
                <DataCard key={data.id} data={data} />
              ))}
            </div>
          </div>
        )}

        {/* Display VETEMENTS items */}
        {vetementsDatas.length > 0 && (
          <div>
            <h2>Vetements:</h2>
            <div className="data-grid">
              {vetementsDatas.map((data) => (
                <DataCard key={data.id} data={data} />
              ))}
            </div>
          </div>
        )}

        {/* Display OBJETS items */}
        {objetsDatas.length > 0 && (
          <div>
            <h2>Objets:</h2>
            <div className="data-grid">
              {objetsDatas.map((data) => (
                <DataCard key={data.id} data={data} />
              ))}
            </div>
          </div>
        )}

        {/* Display BILLETERIE items */}
        {billeterieDatas.length > 0 && (
          <div>
            <h2>Billeterie:</h2>
            <div className="data-grid">
              {billeterieDatas.map((data) => (
                <DataCard key={data.id} data={data} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
